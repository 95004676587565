import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const AllEpisodes = () => (
    <section className="py-5 text-center containe latest-episodes black-band">
      <div className="container">
        <div className="row">
          <div className="col section-header">
            <h1>All Episodes</h1>
          </div>
        </div>
        <div className="row py-5">
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/sleepaway-camp">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Sleepaway Camp</h1>
                          <h2 className="episode-number">13</h2>
                          <div className="episode-body">
                              <p>1983 // Directed by Robert Hiltzik // Starring Felissa Rosa and Karen Fields</p>
                              <p>This week, Bring Me The Axe! heads to Camp Arawak to explore one of slasher history's most unhinged entries, Sleepaway Camp. Be advised, we spoil the twist ending that we spoil right from the get-go.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/sleepaway-camp-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1983 slasher movie, Sleepaway Camp"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/madman">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Madman</h1>
                          <h2 className="episode-number">12</h2>
                          <div className="episode-body">
                              <p>1981 // Directed by Joe Giannone // Starring Gaylen Ross and Tony Nunziata</p>
                              <p>This week Bring Me The Axe takes a look at 1981's Madman, a truly weird entry into the early 80's slasher canon. A cast of pleasant camp counselors at Thanksgiving Gifted Camp face off against an axe murderer.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/madman-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1981 slasher movie, Madman"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/jaws">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Jaws (Bonus)</h1>
                          <h2 className="episode-number">11</h2>
                          <div className="episode-body">
                              <p>1975 // Directed by Steven Spielberg // Starring Roy Scheider and Richard Dreyfus</p>
                              <p>It's a holiday bonus episode for July 4th! Celebrating Steven Spielberg's landmark accomplishment and one of the all-time greatest movies, Jaws!</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/jaws-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1975 killer shark movie, Jaws"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/the-old-dark-house">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">The Old Dark House</h1>
                          <h2 className="episode-number">10</h2>
                          <div className="episode-body">
                              <p>1932 // Directed by James Whale // Starring Boris Karloff and Ernest Thesiger</p>
                              <p>Bring Me The Axe closes out their Pride 2023 series with an enthusiastic love letter to James Whale's 1932 pre-code classic for Universal, The Old Dark House.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/the-old-dark-house-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1932 gothic horror, The Old Dark House"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/vamp">
                <div className="episode">
                    <div className="episode-wrapper">
                        <h1 className="episode-title">Vamp</h1>
                        <h2 className="episode-number">09</h2>
                        <div className="episode-body">
                            <p>1986 // Directed by Richard Wenk // Starring Chris Makepeace and Grace Jones</p>
                            <p>Bring Me The Axe kicks off its Pride 2023 series with a mostly unremarkable movie that remains in the horror movie memory thanks entirely to contributions from gay artists, the 1986 horror comedy Vamp.</p>
                        </div>
                        <p className="episode-link">Listen &#62;&#62;</p>
                        <div className="episode-poster">
                            <div className="poster-overlay-50">&nbsp;</div>
                            <StaticImage
                                src="../images/vamp-poster-sm.jpeg"
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="A poster for the 1986 horror comedy, Vamp"
                                className="img-fluid"
                                placeholder="BLURRED"
                                objectFit="cover"
                            />
                        </div>
                    </div>
                </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/the-house-on-sorority-row">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">The House on Sorority Row</h1>
                          <h2 className="episode-number">08</h2>
                          <div className="episode-body">
                              <p>1982 // Directed by Mark Rosman // Starring Mark Rosman and Eileen Davidson</p>
                              <p>Bryan and Dave close out their School's Out series, taking a look at the 1982 slasher picture, The House on Sorority Row, from director Mark Rosman, a hidden gem of the genre that remarkably passes the Bechdel Test.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/house-on-sorority-row-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1982 slasher movie, The House On Sorority Row"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/final-exam">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Final Exam</h1>
                            <h2 className="episode-number">07</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by Jimmy Huston // Starring Cecile Bagdadi and Joel S. Rice</p>
                                <p>Bryan and Dave take a look at the 1981 slasher also-ran, Final Exam, a movie that should be stuffed down the horror movie memory hole were it not for its one secret weapon, a weirdo nerd character named Radish and his ongoing series of bizarre bon mots.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/final-exam-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 slasher movie, Final Exam"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-howling">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Howling</h1>
                            <h2 className="episode-number">06</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by Joe Dante // Starring Dee Wallace and Patrick MacNee</p>
                                <p>Bryan and Dave break down one of the four werewolf movies released in 1981, Joe Dante's iconic The Howling. It's a wild ride made notable by its awesome special effects and dark comedic tone.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-howling-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 werewolf movie, The Howling"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-sentinel">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Sentinel</h1>
                            <h2 className="episode-number">05</h2>
                            <div className="episode-body">
                                <p>1977 // Directed by Michael Winner // Starring Cristina Raines and Chris Sarandon</p>
                                <p>Bryan and Dave take a look at 1977's The Sentinel. Ostensibly the most Catholic horror movie ever made, it's also about as paranoid as a horror movie can be. One damned soul, alone must stand watch over the gateway to Hell and Alison Parker is next in line.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-sentinel-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1977 haunted house movie, The Sentinel"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/maniac">
                <div className="episode">
                    <div className="episode-wrapper">
                        <h1 className="episode-title">Maniac</h1>
                        <h2 className="episode-number">04</h2>
                        <div className="episode-body">
                            <p>1980 // Directed by William Lustig // Starring Joe Spinell and Caroline Munro</p>
                            <p>This week Bryan and Dave take a look at William Lustig's 1980 roughie, Maniac, starring Joe Spinell and Caroline Munro. Bryan also goes off on a long tangent about the time he met Tom Savini and how it haunted Savini for years.</p>
                        </div>
                        <p className="episode-link">Listen &#62;&#62;</p>
                        <div className="episode-poster">
                            <div className="poster-overlay-50">&nbsp;</div>
                            <StaticImage
                                src="../images/maniac-poster-sm.jpeg"
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="A poster for the 1980 slasher movie, Maniac"
                                className="img-fluid"
                                placeholder="BLURRED"
                            />
                        </div>
                    </div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/pieces">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Pieces</h1>
                <h2 className="episode-number">03</h2>
                <div className="episode-body">
                  <p>1982 // Directed by Juan Piquer Simon // Starring Christopher George and Linda Day George</p>
                  <p>This week, Bryan and Dave take a look at the utterly deranged 1982 slasher picture, Pieces. This one has it all: Chainsaws, gratuitous nudity, buckets of blood, hysterical screaming, and THRILLING TENNIS ACTION! "It's exactly what you think it is."</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-75">&nbsp;</div>
                  <StaticImage
                    src="../images/pieces-poster-sm.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, Pieces"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/butcher-baker-nightmare-maker">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Butcher, Baker, Nightmare Maker</h1>
                <h2 className="episode-number">02</h2>
                <div className="episode-body">
                  <p>1982 // Directed by William Asher // Starring Susan Tyrell and Jimmy McNichol</p>
                  <p>Campy doesn't even begin to cover this one. In what is becoming a disturbing trend at Bring Me The Axe, it's another story about inappropriate family love and the extent that a parent will go to keep their son close to them.</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-50">&nbsp;</div>
                  <StaticImage
                    src="../images/butcher-baker-nightmare-maker-poster-sm.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Butcher, Baker, Nightmare Maker"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
)

export default AllEpisodes